import React, { useEffect, useState, useRef, useCallback } from "react";
import MenuDrawer from "../../Components/MenuDrawer";
import HeaderNew from "../../Components/HeaderNew";
import { Box, Typography, Stack, Card, CardContent } from "@mui/material";
import { typographyStyles, style } from "../../styleSheets/StyleNew";
import MultipleSelect from "../../Common-comp/SelectField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useAuth } from "../../services/Context";
import { LogoCard } from "../../Common-comp/Card";
import { AnalysisAcrossCard } from "../../services/DataFiles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { fetchOverallAcross } from "../../services/Analysis_api";
import PieGraphNew from "../../Common-comp/PieGraphNew";
import ExampleAlignmentButtons from "../../Common-comp/RadioView";
import LineGraph from "../../Common-comp/LineGraphAcross";
import { motion } from "framer-motion";

import {
  CustomizedAccordions,
  MobileViewCard,
} from "../../Components/CustomizedAccordian";

const FilterList = ({ mocksList, setIndex, scrollTo }) => {
  const [defaultVal, setDefaultVal] = useState(mocksList[0]?.title);
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        {" "}
        <Typography
          variant="paragrapgh"
          sx={{ ...style.subHeading, fontSize: "21.96px", color: "black" }}
        >
          Filter
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        defaultValue={defaultVal}
        onClick={() => {
          scrollTo("info"); // Replace "divId" with the actual ID of the element to scroll to
        }}
      >
        {mocksList?.map((item, index) => {
          return (
            <FormControlLabel
              onClick={() => setIndex(index)}
              value={item.title}
              control={<Radio />}
              label={item.title}
              key={item.title}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const GraphLegend = [
  { shade: "#43d200", Value: "Correct" },
  { shade: "#F32121", Value: "Incorrect" },
  { shade: "#2194f3", Value: "Skipped" },
];

const Subjects = [
  { name: "VARC", value: "varc" },
  { name: "LRDI", value: "lrdi" },
  { name: "QA", value: "quants" },
];

const GraphListDetails = [
  { name: "Overall Precentile", value: "overallMocks" },
  { name: "VARC Percentile", value: "varcMocks" },
  { name: "LRDI Percentile", value: "lrdiMocks" },
  { name: "Quants Percentile ", value: "quantsMocks" },
];

const DataTable = ({ data }) => {
  return (
    <TableContainer
      sx={{ p: 2, borderRadius: 4, border: "none", boxShadow: 2 }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.topic}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                {row.topic || row.subtopic}
              </TableCell>
              <TableCell align="left">
                {row.noOfQuestion || row.numberOfQuestions}
              </TableCell>
              <TableCell align="left">
                {row.attempted || row.numberOfAttemptedQuestions}
              </TableCell>
              <TableCell align="left" sx={{ color: "green" }}>
                {row.correct || row.numberOfCorrectAttempt}
              </TableCell>
              <TableCell align="left">
                {row.incorrect || row.numberOfIncorrectAttempt}
              </TableCell>
              <TableCell align="left">
                {row.skipped || row.numberofSkippedQuestion}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CardStyle = {
  fontSize: 8,
  width: "169px",
  height: "52px",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  columnGap: 10,
  alignItems: "center",
  alignContent: "center",
};

const cardsColor = ["#FFD800", "#006CFF", "#46CB18"];

const ListedData =(data, AnalysisData)=>{
   console.log("Daata of listed data", data)
  return [
  
    {
      name: AnalysisData[0].title,
      value: data.noOfQuestion || data.numberOfQuestions,
    },
    {
      name: AnalysisData[1].title,
      value: data.attempted || data.numberOfAttemptedQuestions,
    },
    {
      name: AnalysisData[2].title,
      value: data.correct || data.numberOfCorrectAttempt,
    },
    {
      name: AnalysisData[3].title,
      value: data.incorrect || data.numberOfIncorrectAttempt,
    },
    {
      name: AnalysisData[4].title,
      value: data.skipped || data.numberofSkippedQuestion,
    },
  ];
}
function OverallAcrossMob() {
  const params = useParams();
  const [type, setType] = useState(null); // this state for the type of section
  const [mocksList, setMocksList] = useState([]); // This state for setting the filter for left side list
  const [index, setIndex] = useState(0); // state for setting the index
  const [show, setShow] = useState([]); // this state for table data has been showing
  const [view, setView] = useState("Table"); // View setting state graph or table
  const [response, setResponse] = useState([]); // main Data that come from api set in this state
  const [topics, setTopics] = useState([]); // for weak strong and moderate card state
  const [topicsType, setTopicsType] = useState(null); //for topic and subtopic filter state
  const [graph, setGraph] = useState({
    data: [],
    type: null,
  }); //state for setting the line graph

  const {
    menuBarOpen,
    setMenuBarOpen,
    Backdrop,
    setLoading,
    isLoading,
    showToastMessage,
    isMobile,
  } = useAuth();

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (graph.type) {
      setGraph({ ...graph, data: response[graph.type] });
    }
    setShow(mocksList[index]?.data?.[type]);
  }, [index, type, mocksList, graph.type]);

  useEffect(() => {
    if (response) {
      const topicWiseData = response?.[topicsType] || [];
      setMocksList(topicWiseData);
    }
  }, [topicsType, response]);

  useEffect(() => {
    const uid = JSON.parse(localStorage.getItem("userData"))?._id;
    const fetchData = async (uid, attemptId) => {
      setLoading(true);
      const response = await fetchOverallAcross(uid, attemptId);
      //console.log("Analysis across Data", response);
      if (response?.status === 200) {
        setResponse(response.data);
        const topicWiseData = response.data?.[topicsType] || ["topicWise"];
        setMocksList(topicWiseData); //first time data pre setting

        setLoading(false);
      } else {
        showToastMessage();
        setLoading(false);
        return;
      }
    };
    fetchData(uid, params.attemptId);
  }, []);

  useEffect(() => {
    if (response.analysismetrics && type !== null) {
      const newTopics = { weak: [], moderate: [], strong: [] };

      response.analysismetrics[0]?.["overall"].forEach((e) => {
        if (e.accuracy <= 30) {
          newTopics.weak.push({
            title: "Weak",
            icon: "",
            topic: e.topic,
            accuracy: e.accuracy,
          });
        } else if (e.accuracy > 30 && e.accuracy <= 60) {
          newTopics.moderate.push({
            title: "",
            icon: "",
            topic: e.topic,
            accuracy: e.accuracy,
          });
        } else {
          newTopics.strong.push({
            title: "",
            icon: "",
            topic: e.topic,
            accuracy: e.accuracy,
          });
        }
      });

      setTopics(newTopics);
    }
  }, [response, type]);

    
  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  console.log("Mock List", mocksList);
  console.log("show", show);
  //console.log("type", type);
  //console.log("GRAPH DATA", graph);

  return (
    <>
      <Box component="main">
        {!isMobile && <MenuDrawer />}
        <Box
          sx={{
            position: "absolute",
            left: isMobile ? 0 : "65px",
            height: "100vh",
            overflow: "scroll",
            width: isMobile ? "100%" : "calc(100% - 65px)",
            p: 2,
          }}
        >
          {/* Header */}
          <Box component="header">
            <HeaderNew />
          </Box>

          {isLoading ? (
            <div
              className="d-flex align-items-center flex-column gap-2 justify-content-center"
              style={{ width: "100%", height: "80%" }}
            >
              <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-text">Loading...</div>
              </div>
              <Typography sx={{ fontWeight: 500 }}>
                It may take some longer, Please be patient!
              </Typography>
            </div>
          ) : (
            <>
              {/* Select box */}
              <Box component="div" sx={{ mt: 4}}>
                <Typography
                  sx={{
                    ...typographyStyles.mainHeading,
                    pt: 2,
                  }}
                >
                  {" "}
                  Analysis Across All Mocks
                </Typography>

                <div className="d-flex justify-content-between align-items-start w-100">
                  <div className="mt-4 d-flex flex-wrap gap-2">
                    {mocksList.length > 0 && (
                      <MultipleSelect
                        options={mocksList.map((e, ind) => ({
                          name: e.title,
                          value: ind,
                        }))}
                        setType={setIndex}
                      />
                    )}
                    <MultipleSelect options={Subjects} setType={setType} />
                  </div>
                  <div>
                    <span>Change View</span>{" "}
                    <ExampleAlignmentButtons
                      setValue={setView}
                      value={view}
                      options={[
                        {
                          label: "Table",
                          image: "/tableView.png",
                        },
                        {
                          label: "Graph",
                          image: "/graphView.png",
                        },
                      ]}
                      style={{ width: 110, height: 40 }}
                    />
                  </div>
                </div>
              </Box>

              {/* Main Section */}
              <Box
                component="main"
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "auto",
                  mt: 3,
                  flexWrap: "wrap",
                }}
              >
                <Backdrop
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    color: "#fff",
                  }}
                  open={menuBarOpen}
                  onClick={() => setMenuBarOpen(false)}
                />

                
          

                {/*Question side box start*/}
                <Box
                  className={
                    view === "Table" ? "table-section" : "graph-section"
                  }
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "#F6F7F8",
                    p: 2,
                    borderRadius: "15px",
                    
                    my: 2,
                  }}
                  ref={scrollableDivRef}
                >
                  {/* Switching sections */}

                  {view === "Table" ? (
                    <>
                      <Stack direction="row" justifyContent={"flex-end"}>
                        <MultipleSelect
                          options={GraphListDetails}
                          defaultValue={topicsType}
                          setType={(selectedValue) =>
                            setGraph({
                              ...graph,
                              type: selectedValue,
                            })
                          }
                        />
                      </Stack>
                      <Stack
                        justifyContent={"center"}
                        my={5}
                        direction="row"
                        id="lineGraph"
                      >
                        <motion.div
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.0 }}
                          style={{ width: "80vw", height: "20em" }}
                        >
                          {Object.keys(graph).length > 0 ? (
                            <LineGraph
                              Data={graph.data}
                              dataKeyX={"name"}
                              ykey={"percentile"}
                              xAxisTitle={"(Mocks)"}
                              yAxisTitle={"(Percentile)"}
                            />
                          ) : (
                            <div className="text-center">
                              Graph Data Not Available
                            </div>
                          )}
                        </motion.div>
                      </Stack>
                      <hr />
                      {/* Cards of table*/}
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {mocksList.length > 0 && (
                          <>
                            <MultipleSelect
                              options={[
                                {
                                  name: "Sub-Topics",
                                  value: "Subtopic",
                                },
                                { name: "Topics", value: "topicWise" },
                              ]}
                              setType={setTopicsType}
                            />
                          </>
                        )}
                      </Box>

                      {/* Table */}
                      <Box component="div" id="info" mt={4}>
                        {show && show.length > 0 ? (
                          // <DataTable data={show} />
                          show.map((item) => {
                            return (
                              <div className="mt-3 mx-auto">
                                <MobileViewCard
                                  data={{
                                    percentile: item.topic || item.subtopic, //This props send in percentile key due to css  see the mobile view component
                                  }}
                                  listData={ListedData(
                                    item,
                                    AnalysisAcrossCard
                                  )}
                                  icon={
                                    topicsType === "topicWise"
                                      ? "/CardsIcons/mainTopic.png"
                                      : "/CardsIcons/subtopic.png"
                                  }
                                  infoIcon={"/circle-info-solid.svg"}
                                  //   backgroundColor="#FFC107"
                                  title={item.topic || item.subtopic}
                                  //   titleDescription="Topper Analysis"
                                  style={{ iconSize: 30 }}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <Card
                            className="text-center w-100"
                            sx={{ padding: 2, borderRadius: 3 }}
                          >
                            <h6 className="pt-2">
                              {topicsType &&
                                topicsType.charAt(0).toUpperCase() +
                                  topicsType.slice(1).toLowerCase()}{" "}
                              data not available
                            </h6>
                          </Card>
                        )}
                      </Box>

                      {/* Cards of weak , moderate and  strong */}
                      <Typography
                        sx={{
                          ...typographyStyles.mainHeading,
                          py: 2,
                          fontSize: 39,
                        }}
                      >
                        {" "}
                        Analysis Metrics
                      </Typography>
                      <div className="my-2 d-flex flex-wrap justify-content-around">
                        {Object.keys(topics).length > 0 &&
                          ["Weak", "Moderate", "Strong"].map((type, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex flex-column p-2"
                                  key={index}
                                >
                                  <Typography
                                    sx={{
                                      ...typographyStyles.mainHeading,
                                      lineHeight: 4,
                                      fontSize: "17.02px",
                                      pl: 2,
                                    }}
                                  >
                                    {" "}
                                    <span className="me-2">
                                      <img
                                        src={
                                          type === "Weak"
                                            ? "/CardsIcons/broken.png"
                                            : type === "Moderate"
                                            ? "/CardsIcons/flag.png"
                                            : "/CardsIcons/arm.png"
                                        }
                                        width={28}
                                        className="img-fluid"
                                      />
                                    </span>{" "}
                                    {type}
                                  </Typography>
                                  <Card
                                    key={type}
                                    sx={{
                                      minHeight: "19.188em",
                                      minWidth: "17.938em",
                                      backgroundColor:
                                        cardsColor[index % cardsColor.length],
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 3,
                                    }}
                                  >
                                    <CardContent>
                                      {topics[type.toLowerCase()].length > 0 ? (
                                        topics[type.toLowerCase()].map(
                                          (item, ind) => (
                                            <motion.div
                                              key={ind}
                                              initial={{
                                                opacity: 0,
                                                scale: 0.5,
                                              }}
                                              animate={{ opacity: 1, scale: 1 }}
                                              transition={{ duration: 0.5 }}
                                            >
                                              <LogoCard
                                                cardTitle={item.topic}
                                                icon={"/Acc.png"}
                                                style={{
                                                  ...CardStyle,
                                                  fontSize: "15px",
                                                  iconSize: 24,
                                                  width: "17.1em",
                                                  marginBottom: "15px",
                                                  justifyContent: "flex-end",
                                                  columnGap: "10px",
                                                  borderRadius: "15px",
                                                }}
                                              />
                                            </motion.div>
                                          )
                                        )
                                      ) : (
                                        <>
                                          {" "}
                                          <LogoCard
                                            cardTitle={"No Topic"}
                                            // icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              fontSize: "15px",
                                              iconSize: 24,
                                              width: "17.1em",
                                              marginBottom: "15px",
                                              justifyContent: "flex-end",
                                              columnGap: "10px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </>
                                      )}
                                    </CardContent>
                                  </Card>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Graphs view */}

                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.0 }}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          rowGap: 5,
                          width:"100%",
                          justifyContent: "space-around",
                        }}
                      >
                        {show &&
                          show.map((item, ind) => {
                            //console.log(show);
                            return (
                              <Box key={ind}>
                                <Box
                                  sx={{ width: "16em", height: "16em", my: 2 }}
                                >
                                  <Typography
                                    lineHeight={1}
                                    sx={{
                                      ...typographyStyles.subHeading,
                                      textAlign: "center",
                                    }}
                                    color="black"
                                  >
                                    {item.topic || item.subtopic}
                                  </Typography>

                                  <PieGraphNew
                                    data={{
                                      topic: item.topic || item.subtopic,
                                      correct:
                                        item.correct ||
                                        item.numberOfCorrectAttempt,
                                      incorrect:
                                        item.incorrect ||
                                        item.numberOfIncorrectAttempt,
                                      skipped:
                                        item.skipped ||
                                        item.numberofSkippedQuestion,
                                    }}
                                    color={GraphLegend.map((e) => e.shade)}
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                      </motion.div>

                      {show && show.length > 0 ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"center"}
                          mt={2}
                        >
                          {GraphLegend.map((e, ind) => {
                            return (
                              <div
                                className="d-flex gap-1 align-items-center"
                                key={ind}
                              >
                                <div
                                  style={{
                                    borderRadius: "40%",
                                    height: "24px",
                                    width: "24px",
                                    background: e.shade,
                                  }}
                                ></div>
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: "var(--font-inter)",
                                    fontWeight: 600,
                                  }}
                                >
                                  {e.Value}
                                </span>
                              </div>
                            );
                          })}
                        </Stack>
                      ) : (
                        <div className="text-center pt-5">
                          <h6>
                            {topicsType &&
                              topicsType.charAt(0).toUpperCase() +
                                topicsType.slice(1).toLowerCase()}{" "}
                            data not Available
                          </h6>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </Box>
              {/*Question side box end*/}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default OverallAcrossMob;
