import React, { useEffect } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { OutlinedInput, Typography, TableBody } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../services/Context";
import HeaderNew from "../Components/HeaderNew";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { DetailCards } from "../Common-comp/Card";
import { getGoalTrackerData } from "../services/Analysis_api";
import ExampleAlignmentButtons from "../Common-comp/TextView";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
} from "recharts";
import { BarChart, XAxis, YAxis, Bar } from "recharts";
import { ResponsiveContainer } from "recharts";
import { Tooltip } from "recharts";
import { motion } from "framer-motion";
import CustomizedAccordions from "../Common-comp/Accordian";
import { typographyStyles } from "../styleSheets/StyleNew";
import { GiStairsGoal } from "react-icons/gi";
import {encode,decode } from "base-64";

export default function GoalTracker() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const {
    menuBarOpen,
    setMenuBarOpen,
    Backdrop,
    setLoading,
    isLoading,
    showToastMessage,
    isMobile
  } = useAuth();

  const [percentile, setPercentile] = useState(90);
  const [userData, setUserData] = useState();
  const [isUserVerified, setUserVerified] = useState(false);
  const navigate = useNavigate();
  const [mockList, setMockList] = useState();
  const [mockData, setMockData] = useState([]);
  // const [options, setOptions] = useState([]);
  const options = [{ name: "JKD", value: "jkd" }];
  const [mockIndex, setMockIndex] = useState(0);
  const [defVal, setDefVal] = useState("fewf");
  //console.log(userData);

  useEffect(() => {
    if (mockList?.length) {
      setMockData(mockList[mockIndex]);
    }
  }, [mockIndex]);
  //console.log(mockData);

  useEffect(() => {
    if (userData) {
      setUserVerified(true);
    }
  }, [userData]);

  const cellStyle = {
    borderBottom: "none",
    fontSize: 13,
    paddingBottom: 2,
  };
  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    disableScrollLock: true,
    MenuListProps: {
      autoFocusItem: true,
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const infoStyle = {
    textstyle: {
      fontSize: "10px",
      fontFamily: "var(--font-inter)",
      fontWeight: "bold",
    },
    divStyle: {
      width: "46px",
      height: "11px",
      background: "linear-gradient(180deg, #21D3E7 0%, #0099F4 100%)",
      borderRadius: "20px",
    },
  };

  // api call
  useEffect(() => {
    getData();
  }, []);

  // function for fetching data
  const { attemptId } = useParams();
  const [goalData, setGoalData] = useState([]);
  const [yourData, setYourData] = useState([]);
  const [weakTopics, setWeakTopics] = useState();
  const [bschool, setBschool] = useState([]);
  const [missedBschool, setMissedBschool] = useState([]);
  const [view, setView] = useState("Percentile(Radar)");
  const [articleList, setArticleList] = useState();

  const getData = async () => {
    setLoading(true);
    const uid = JSON.parse(localStorage.getItem("userData"))?._id;
    const res = await getGoalTrackerData(attemptId, uid);
    if (res?.status == 200) {
      //console.log(res);
      setMockList(res.data.mockWise);
      setYourData(res.data.yourData);
      setGoalData(res.data.goalData);
      setWeakTopics(res.data.weakTopics[0]);
      setBschool(res.data.bschools);
      setArticleList(res.data.articleList);
      setMissedBschool(res.data.missedBSchools);
      setDefVal(res.data.mockWise[res.data.mockWise.length - 1].title);
      setMockData(res.data.mockWise[res.data.mockWise.length - 1]);
      setLoading(false);
    } else {
      //console.log("error", res);
      setLoading(false);
    }
  };

  return (
    <div style={{ userSelect: "none" }}>
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <Box>
         {!isMobile && <MenuDrawer />}
        </Box>

        <Box
          sx={{
            ml: isMobile ?0:"65px",
            background: isLoading ? "" : "url(/GoalTrackerBackground.jpg)",
            backgroundSize: "cover",
            height: "100vh",
            p: 2,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Header */}
          <Box component="header">
            <HeaderNew
              logoPath={"/iQuantaWhite.png"}
              style={{ color: "white" }}
            />
          </Box>

          {/* Header end  */}
          {/* main Section start */}
          {isLoading ? (
            <div
              className="d-flex align-items-center flex-column gap-2 justify-content-center"
              style={{ width: "100%", height: "80vh" }}
            >
              <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-text">Loading...</div>
              </div>
            </div>
          ) : (
            <>
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", mt: 2 }}
              >
                <Typography
                  sx={{ ...typographyStyles.mainHeading }}
                  color="white"
                >
                  {" "}
                  Goal Tracker via iRadar <GiStairsGoal className="pb-2" />
                </Typography>{" "}
                {/* Two div */}
                <Box component="div">
                  <Box
                    component="div"
                    sx={{
                      position: "absolute",
                      marginTop: 15,
                      zIndex: 0,
                    }}
                  >
                    {view === "Percentile(Radar)" ? (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 1.0,
                        }}
                      >
                        <ResponsiveContainer width={700} height={480}>
                          <RadarChart
                            outerRadius={220}
                            data={mockData?.data}
                            legendType="plain"
                          >
                            <PolarGrid />
                            <PolarAngleAxis
                              dataKey="name"
                              tick={{ fill: "white" }}
                            />
                            {/* <PolarRadiusAxis angle={30} domain={[0, 100]} /> */}
                            <Radar
                              name="Targeted %ile"
                              dataKey="targetPercentile"
                              stroke="#7673FF"
                              fill="#7673FF"
                              fillOpacity={0.6}
                            />
                            <Radar
                              name="Scored %ile"
                              dataKey="scorePercentile"
                              stroke="#59DE66"
                              fill="#59DE66"
                              fillOpacity={0.6}
                            />
                            <Tooltip />
                            <Legend
                              layout="horizontal"
                              verticalAlign="bottom"
                              align="center"
                              wrapperStyle={{ marginBottom: 75 }}
                            />
                          </RadarChart>
                        </ResponsiveContainer>
                      </motion.div>
                    ) : view === "Score(Bar)" ? (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 1.0,
                        }}
                      >
                        <BarChart
                          width={600}
                          height={380}
                          data={mockData?.scoreData}
                        >
                          {/* <CartesianGrid strokeDasharray="3 3" /> */}
                          <XAxis dataKey="name" tick={{ fill: "white" }} />
                          <YAxis domain={[0, 100]} tick={{ fill: "white" }} />
                          <Tooltip />
                          <Legend />
                          <Bar
                            barSize={60}
                            dataKey="TargetScore"
                            fill="#641CFF"
                          />
                          <Bar
                            barSize={60}
                            dataKey="YourScore"
                            fill="#59DE66"
                          />
                        </BarChart>
                      </motion.div>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box component="div" sx={{ display: "flex", gap: 2 }}>
                  <Box
                    component="div"
                    sx={{
                      width: "40vw",
                      height: 220,
                      zIndex: 999,
                      borderRadius: "25px",
                      background: "white",
                      p: 1,
                      position: "absolute",
                      top: 450,
                      right: 50,
                    }}
                  >
                    <DetailCards
                      cardContent={
                        <CustomizedAccordions
                          data={weakTopics}
                          articleList={articleList}
                        />
                      }
                      heading={"Personalized Expert Analysis & Strategy"}
                      logoPath={"/CardsIcons/idea1.png"}
                    />
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      width: "40vw",
                      height: 300,
                      borderRadius: "17px",
                      background: "white",
                      zIndex: 99,
                      p: 1,
                      overflowX: "hidden",
                      position: "absolute",
                      top: 105,
                      right: 50,
                    }}
                  >
                    <DetailCards
                      logoPath={"/goalSchool.png"}
                      cardContent={
                        <TableContainer>
                          <Table
                            sx={{ border: "none" }}
                            aria-label="simple table"
                          >
                            <TableBody>
                              {bschool &&
                                bschool.map((item, ind) => {
                                  return (
                                    <TableRow
                                      key={ind}
                                      sx={{
                                        lineHeight: "unset",
                                        borderBottom: "1px solid #E1E1E1",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          paddingRight: 2,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {ind + 1}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                          width: "40% !important",
                                        }}
                                        align="left"
                                      >
                                        {item.name}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {item.highestSalary + " LPA" || "tbd"}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                        }}
                                        align="left"
                                      >
                                        {item.avgSalary + " LPA" || "tbd"}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          ...cellStyle,
                                          fontWeight: 600,
                                          color:
                                            item.bschool === "Missed"
                                              ? "red"
                                              : "green",
                                        }}
                                        align="left"
                                      >
                                        {item.bschool}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                      heading={"B-Schools You Can Crack"}
                      style={{}}
                    />
                  </Box>
                </Box>
                {/* Graphs start */}
                
                <Box>
                  {" "}
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "15px ",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      p: 1,
                      columnGap: 2,
                      // backgroundColor: "white",
                      zIndex: 0,
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        height: 41,
                        borderRadius: "15px ",
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                        backgroundColor: "white",
                        zIndex: 0,
                      }}
                    >
                      {" "}
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 3,
                        }}
                      >
                        <Select
                          defaultValue={defVal}
                          // value={selected}

                          input={
                            <OutlinedInput
                              sx={{
                                width: "100%",
                                borderRadius: 2,
                                height: "100%",
                                fontSize: "12px",
                                fontWeight: 700,
                                fontFamily: "var(--font-inter)",

                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 1,
                                  borderColor: "transparent",
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 1,
                                    borderColor: "transparent",
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 2,
                                    borderColor: "transparent",
                                  },
                              }}
                            />
                          }
                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>Select{ " " + type }</em>;
                          //   }

                          //   return selected;
                          // }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Select value" }}
                        >
                          <MenuItem value={""}>
                            <em>Select</em>
                          </MenuItem>
                          {mockList &&
                            mockList.map((item, ind) => (
                              <MenuItem
                                key={ind}
                                value={item.title}
                                sx={{
                                  fontFamily: "var(--font-inter)",
                                  fontSize: "11px",
                                  fontWeight: "600",
                                }}
                                onClick={(e) => {
                                  setMockIndex(ind);
                                }}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography
                      sx={{ letterSpacing: 1 }}
                      fontSize={16}
                      color="white"
                    >
                      Select Your Map
                    </Typography>
                    <div>
                      <ExampleAlignmentButtons
                        setValue={setView}
                        value={view}
                        options={[
                          {
                            label: "Percentile(Radar)",
                            image: "/tableView.png",
                          },
                          {
                            label: "Score(Bar)",
                            image: "/graphView.png",
                          },
                        ]}
                        style={{
                          backgroundMain: "#b7bdb9",
                          higlightBackground: "#b7bdb9",
                          buttonWidth: 145,
                          buttonHeight: 40,
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {/* main Section end */}
        </Box>
      </Box>
    </div>
  );
}
