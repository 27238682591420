import  React,{ useState, useEffect }   from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IoIosArrowDropupCircle } from "react-icons/io";



const drawerBleeding = 50;

const Root = styled("div")(({ theme }) => ({
    zIndex: 0,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#e8efff",
}));

const ArrowUpIcon = styled(IoIosArrowDropupCircle)(({ theme, open }) => ({
  color: theme.palette.mode === "light" ? grey[900] : grey[900],
  cursor: "pointer",
  zIndex: 2000,
  mx: "auto",
  visibility: "visible",
  marginLeft: "46%",
  marginTop: `-65px`,
  fontSize: "2rem",
  fontWeight: "bold",

  transform: open && "rotate(180deg)",
}));

export default function CustomEdgeDrawer(props) {
    const { window, content, Navigation } = props;

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSwipe = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  
    
     console.log("component re render")
  return (
    <Root>
      <CssBaseline />

      {/* <Box sx={{ textAlign: "center", pt: 1 }}>
        <Button onClick={toggleDrawer}>Open</Button>
      </Box> */}
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={handleSwipe(false)}
        onOpen={handleSwipe(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            height: `calc(60% - ${drawerBleeding}px)`,
            overflow: "visible",
            backgroundColor: "#e8efff",
          },
        }}
      >
        <ArrowUpIcon onClick={toggleDrawer} open={open} />
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            py: 1,
            textAlign: "center",

            // backgroundColor: "#F1F4F9",
          }}
        >
           {!open && <h6 className="fw-bold mt-3 mx-auto">
            {" "}
            Swipe up for More Details | Change Question
          </h6>}
          <h6 className="fw-bold mt-3 mx-auto">View All Questions</h6>
          <Box
            sx={{
              p: 2,
            }}
          >
          
                <Box textAlign={"center"}>{Navigation}</Box>
                <hr />
            
            {content}
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}
