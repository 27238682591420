import React, { useRef, useState } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import { useAuth } from "../services/Context";
import { typographyStyles } from "../styleSheets/StyleNew";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Typography, Card, CardContent, CardHeader } from "@mui/material";
import { LogoCard } from "../Common-comp/Card";
import { getMockComparison } from "../services/Analysis_api";
import { useEffect } from "react";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { getPrevMockData } from "../services/Analysis_api";
import TransitionsModal from "../Components/Modal";
import {CustomizedAccordions, MobileViewCard} from "../Components/CustomizedAccordian";

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function getStyles(name, MockName, theme) {
  return {
    fontWeight:
      MockName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


function ListedData(data) {
  return [
    { name: "Score", value: data?.score },
    {
      name: " Number of Questions Attempted",
      value: data?.attempted,
    },
    {
      name: " Number of Correct Questions Attempted",
      value: data?.attemptedCorrect,
    },
    {
      name: "  Number of Incorrect Questions Attempted",
      value: data?.attempted - data?.attemptedCorrect,
    },
    {
      name: "Number of Skipped Questions ",
      value: data?.skipped,
    },
    {
      name: "Average time spent per question",
      value: data?.avgTime + "(sec)",
    },
  ];
}
function MockComparison() {
  const Subjects = [
    { name: "Varc" },
    { name: "Quants" },
    { name: "LRdi" },
    { name: "MBA" },
    { name: "MIA" },
  ];

  const OuterCardStyle = {
    width: 246,
    height: "100%",
    ...typographyStyles.subHeading,
    fontSize: "15px",
    background: "#F5F9FF",
    boxShadow: "",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(63, 105, 255, 0.25)",
    p: 1,
  };

  const innerCardStyle = {
    height: "auto",
    justifyContent: "space-around",
    iconSize: 25,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    infoIcon: 20,
  };

  const [MockName, setMockName] = React.useState([]);
  const ref = useRef(null);

  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;
    setMockName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //console.log(value);
    try {
      const uid = JSON.parse(localStorage.getItem("userData"))?._id;
      const res = await getPrevMockData(value, uid);
      //console.log(res);
      if (res?.status == 200) {
        setPrevMock(res?.data.data);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const {
    menuBarOpen,
    setMenuBarOpen,
    Backdrop,
    isLoading,
    setLoading,
    truncateText,
    isMobile,
  } = useAuth();

  const isWindow = JSON.parse(window.localStorage.getItem("__wodniw"));
  useEffect(() => {
    //console.log(isWindow);
    if (isWindow) {
      ref.current.style.display = "none";
    } else {
      getData();
    }
  }, []);

  const { mockId, attemptId } = useParams();
  const [result, setResult] = useState();
  const [prevMock, setPrevMock] = useState();
  const [topper, setTopper] = useState();
  const [prevMockList, setPrevMockList] = useState();

  const [compMock, setCompMock] = useState();

  const getData = async () => {
    setLoading(true);
    const uid = JSON.parse(localStorage.getItem("userData"))?._id;

    try {
      const res = await getMockComparison(mockId, attemptId, uid);
      if (res?.status == 200) {
        //console.log(res.data);
        setResult(res.data?.result);
        setTopper(res.data?.topperResult);
        setPrevMock(res.data?.preresult);
        setPrevMockList(res.data?.previousMocks);
        // setCompMock(res.data?.previousMocks[0]);
        setLoading(false);
      } else {
        setLoading(false);
        //console.log("error", res);
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      showToastMessage(err?.response?.data?.msg);
    }
  };
  //console.log(result, topper, prevMock);
  // //console.log(compMock);
  const showToastMessage = (msg) => {
    toast.error(
      msg == undefined
        ? "Some error occurred! Please reload the page."
        : msg.toUpperCase(),
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return (ref.current.style.display = "none");
  };

  console.log("prev", prevMock);
  return (
    <>
      <TransitionsModal isOpen={isWindow} />
      <ToastContainer />{" "}
      <Box component="main">
        {!isMobile && <MenuDrawer />}

        <Box
          sx={{
            position: "absolute",
            left: isMobile ? 0 : "65px",
            height: "100vh",
            width: isMobile ? "100%" : "calc(100% - 65px)",
            p: 2,
          }}
          ref={ref}
        >
          <Box component="header">
            <HeaderNew />
          </Box>
          {isLoading ? (
            <div
              className="d-flex align-items-center flex-column gap-2 justify-content-center"
              style={{ width: "100%", height: "80%" }}
            >
              <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-text">Loading...</div>
              </div>
              <Typography sx={{ fontWeight: 500 }}>
                It may take some longer, Please be patient!
              </Typography>
            </div>
          ) : (
            <Box
              component="section"
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                mt: 3,
              }}
            >
              <Backdrop
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer - 1,
                  color: "#fff",
                }}
                open={menuBarOpen}
                onClick={() => setMenuBarOpen(false)}
              />

              <Box
                sx={{ p: 2, display: { xs: "none", sm: "none", md: "block" } }}
                className="comparison-Section"
              >
                <Typography
                  sx={{
                    ...typographyStyles.mainHeading,
                    pt: 2,
                  }}
                >
                  {" "}
                  Mock <br /> Comparison
                </Typography>
                <ul
                  className="list-unstyled "
                  style={{
                    lineHeight: "4.4em",
                    ...typographyStyles.subHeading,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  <li>Score</li>
                  <li>Number of Questions Attempted</li>
                  <li>Number of Correct Questions Attempted</li>
                  <li>Number of Incorrect Questions Attempted</li>
                  <li>Number of Skipped Questions </li>
                  <li>Average time spent per question</li>
                </ul>
              </Box>

              <Box
                sx={{
                  mt: 4,
                  display: { xs: "none", sm: "none", md: "flex" },
                  columnGap: 5,
                  alignItems: "center",
                }}
              >
                {result && (
                  <OuterCard
                    data={result}
                    miniCard={
                      <LogoCard
                        icon={"/click 1.svg"}
                        infoIcon={"/circle-info-solid.svg"}
                        cardTitle={
                          <>
                            <Typography
                              variant="h4"
                              sx={{ fontSize: 17 }}
                              color={"black"}
                            >
                              {result?.percentile}{" "}
                              <span style={{ fontSize: "15px" }}>%ile</span>
                            </Typography>
                            <Typography sx={{ color: "#809FB8", fontSize: 15 }}>
                              {truncateText(result?.title, 3)}
                            </Typography>
                          </>
                        }
                        infomatoryText={
                          "This shows analysis overview of your performance in this mock."
                        }
                        style={{ ...innerCardStyle }}
                      />
                    }
                    style={OuterCardStyle}
                  />
                )}
                {prevMock && Object.keys(prevMock)?.length > 0 && (
                  <OuterCard
                    data={prevMock}
                    icon={"/click 1.svg"}
                    miniCard={
                      <LogoCard
                        infomatoryText={
                          "This shows analysis overview of your performance in this mock."
                        }
                        cardTitle={
                          <>
                            <Typography
                              variant="h4"
                              sx={{ fontSize: 17 }}
                              color={"black"}
                            >
                              {prevMock?.percentile}
                              <span style={{ fontSize: "15px" }}>%ile</span>
                            </Typography>
                            <Typography sx={{ color: "#809FB8", fontSize: 15 }}>
                              {truncateText(prevMock?.title, 3)}
                            </Typography>
                          </>
                        }
                        style={{ ...innerCardStyle }}
                        icon={"/click 1.svg"}
                        infoIcon={"/circle-info-solid.svg"}
                        select={
                          <SelectBox
                            onSelect={handleChange}
                            mockName={MockName}
                            options={prevMockList}
                            getPrevMockData={getPrevMockData}
                          />
                        }
                      />
                    }
                    style={OuterCardStyle}
                  />
                )}
                {topper && (
                  <OuterCard
                    data={topper}
                    miniCard={
                      <LogoCard
                        cardTitle={
                          <>
                            <Typography
                              variant="h4"
                              sx={{ fontSize: 17 }}
                              color={"black"}
                            >
                              {topper?.percentile}{" "}
                              <span style={{ fontSize: "15px" }}>%ile</span>
                            </Typography>
                            <Typography sx={{ color: "", fontSize: 15 }}>
                              {" "}
                              {truncateText(topper?.title, 3)}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: 700 }}
                            >
                              {" "}
                              {"Topper Analysis"}
                            </Typography>
                          </>
                        }
                        style={{ ...innerCardStyle, background: "#FFECB9" }}
                        icon={"/top-rated.png"}
                        infoIcon={"/circle-info-solid.svg"}
                        infomatoryText={
                          "This shows mock topper's performance overview in this mock"
                        }
                      />
                    }
                    style={{ ...OuterCardStyle, background: "#FFC107" }}
                  />
                )}
              </Box>

              <Box
                component="div"
                sx={{
                  mt: 2,
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                  rowGap: 3,
                  flexDirection: "column",
                  alignItems: "center",
                  width:"100%"
                }}
              >
                <Typography
                  sx={{
                    ...typographyStyles.mainHeading,
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Mock Comparison
                </Typography>
                {result && (
                  <MobileViewCard
                    data={result}
                    icon={"/click 1.svg"}
                    infoIcon={"/circle-info-solid.svg"}
                    listData={ListedData(result)}
                  />
                )}

                {prevMock && Object.keys(prevMock)?.length > 0 && (
                  <MobileViewCard
                    data={prevMock}
                    listData={ListedData(prevMock)}
                    icon={"/click 1.svg"}
                    infoIcon={"/circle-info-solid.svg"}
                    select={
                      <SelectBox
                        onSelect={handleChange}
                        mockName={MockName}
                        options={prevMockList}
                        getPrevMockData={getPrevMockData}
                      />
                    }
                  />
                )}

                {topper && (
                  <MobileViewCard
                    data={topper}
                    listData={ListedData(topper)}
                    icon={"/top-rated.png"}
                    infoIcon={"/circle-info-solid.svg"}
                    backgroundColor="#FFC107"
                    titleDescription="Topper Analysis"
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}


const OuterCard = ({ style, miniCard, data }) => {
  return (
    <Card sx={{ ...style }}>
      <Box component="div" sx={{ height: "20%" }}>
        {miniCard}
      </Box>
      <CardContent>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.score}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attempted}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attemptedCorrect}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attempted - data?.attemptedCorrect}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.skipped}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.avgTime + "(sec)"}</li>
        </ul>
      </CardContent>
    </Card>
  );
};

const SelectBox = ({ onSelect, mockName, options, getPrevMockData }) => {
  const theme = useTheme();
  return (
    <div>
      <FormControl sx={{ m: 1, mt: 0, width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={mockName}
          onChange={onSelect}
          sx={{ height: "30px" }}
          MenuProps={MenuProps}
          displayEmpty={true}
        >
          <MenuItem value="" disabled>
            Select Mock
          </MenuItem>
          {options?.map((item, index) => (
            <MenuItem
              key={index}
              value={item.attemptId}
              style={getStyles(item._id, mockName, theme)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MockComparison;
