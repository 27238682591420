import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { getVerified, validateToken } from "../services/Mock_api";
import Modal from "@mui/material/Modal";
import { SubHeading } from "./../styleSheets/Style";
import { MyButton } from "./../styleSheets/Style";
import { Typography } from "@mui/material";
import { SubmitButton } from "../styleSheets/Style";
import { InfinitySpin } from "react-loader-spinner";
import { Image } from "react-bootstrap";
import { submitSection } from "../services/Mock_api";
import { Button } from "antd";
import Box from "@mui/material/Box";
import { discardMock } from "../services/Mock_api";

import { encode, decode } from "base-64";
import isBase64 from "is-base64";

const MainUserAuth = () => {
  const navigate = useNavigate();
  const { email, otp, mockId, setId } = useParams(); // send DATA TO VERIFICATION API FOR
  const [loader, setLoader] = useState(true);

  const [openModal, setModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    textAlign: "",
    height: 400,
    bgcolor: "white",
    borderRadius: "10px ",
    boxShadow: 24,
    p: 0,
    m: 0,
  };
  const buttonStyle = {
    background: "linear-gradient(91.59deg, #FD4153 18.67%, #F77A5B 98.68%)",
    width: "138px",
    color: "#fff",
    borderRadius: "20px",
  };

  useEffect(() => {
    const isMobileOrTablet = window.matchMedia("(max-width:1000px)").matches;
    let decodedUserData = localStorage.getItem("userData");
    let userData;
      // console.log("decode::1", isBase64(decodedUserData));
    if (decodedUserData) {
      // console.log("decode::2",decodedUserData);
      if (isBase64(decodedUserData)) {
        // console.log("if case is working")
        userData = JSON.parse(decode(decodedUserData));
        // console.log("decode user::",userData);
        localStorage.setItem("userData", JSON.stringify(userData));
      } else {
        // console.log("else case is working");
        userData = JSON.parse(decodedUserData);
      }
    } else {
      userData = null;
    }

   if (userData && userData?.email === email) {
      //alert("else if enter");
      const questionStatusLocal = localStorage.getItem("questionStatus");
      let storedQuestionStatus = null;

      if (
        questionStatusLocal !== "undefined" &&
        questionStatusLocal !== undefined &&
        questionStatusLocal !== "null" &&
        questionStatusLocal !== null
      ) {
        storedQuestionStatus = JSON.parse(
          localStorage.getItem("questionStatus")
        );
      }

      //console.log("decode questions", storedQuestionStatus);

      if (storedQuestionStatus) {
        validateTokenFunc(userData.uid,email,otp);
        setModal(true);
      } else {
        startVerification(); // ?doubtfu;!!!!!!!
      }
    } else if (userData && userData?.email !== email) {
      // cross user(User trying to log in with different account)
      showToastMessage("New Login Detected!");
      // //alert("maNTHAN")
      startVerification();
    } else {
      //alert("else main enter");

      startVerification();
    }
  }, []);

  //resume mock(if question data is present)

  const resumeMock = async() => {
    // const storedQuestionStatus = JSON.parse(
    //   decodeURIComponent(window.atob(localStorage.getItem("questionStatus")))
    // );
    const storedQuestionStatus = JSON.parse(
      localStorage.getItem("questionStatus")
    );
    //console.log("decode question statyus", storedQuestionStatus);

    const prevMockId = decode(localStorage.getItem("currMockId"));
    const type = storedQuestionStatus[0].section;
    //console.log(type);
    if (type === "VARC") {
      navigate(`/main`, {
        state: {
          mockId: prevMockId,
          type: "varc",
        },
      });
    } else if (type === "LRDI") {
      navigate(`/main`, {
        state: {
          mockId: prevMockId,
          type: "lrdi",
        },
      });
    } else if (type === "QUANTS") {
      navigate(`/main`, {
        state: {
          mockId: prevMockId,
          type: "quants",
        },
      });
    }
    
  };

  // Removing question data

  const eraseQuestionData = async () => {
    const prevAttemptId = decode(localStorage.getItem("attemptId"));
    const uid = JSON.parse(localStorage.getItem("userData"))?._id;
    // localStorage.clear();
    setModal(false);
    setLoader(true);
    try {
      const res = await discardMock(prevAttemptId, uid);
      if (res?.status == 200) {
        localStorage.clear();
        setLoader(false);
        showToastMessageForDiscard();
        setTimeout(() => {
          window.location.href = "https://www.iquanta.in/cat-mock-test";
        }, 2000);
      }
    } catch (err) {
      showToastMessage(err?.response?.data?.message);
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "https://www.iquanta.in/cat-mock-test";
      }, 2000);
      //console.log(err);
    }
  };

  const validateTokenFunc = async (uid,email,otp) => {
    try {
      const response = await validateToken(uid,email,otp);
      if (response.status == 200) {
        localStorage.setItem("auth_token", response.data.accessToken);
      }
    } catch (err) {
      showToastMessage(err?.response?.data?.message);
      console.log(err);
      
      setTimeout(() => {
        window.location.href = "https://www.iquanta.in/cat-mock-test";
      }, 2000);
      return false;
    }
  };

  // Function for VERIFICATION
  const startVerification = async () => {
    //alert("verifying");
    try {
      localStorage.clear();
      const response = await getVerified(email, otp, mockId);
      console.log("MainUser resposne", response);
      // console.log("start verification", response);
      if (response?.status == 200) {
        //alert("response receive");

        localStorage.setItem("auth_token", response?.data?.accessToken);
        //localStorage.setItem("mockTitle",response?.data?.mockTitle)
        const userData = {
          ...response?.data?.data,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        const arr_length = response?.data?.attemptList?.length;
        console.log(arr_length);
        if (arr_length == 0) {
          //alert("naviagte onboarding");
          //console.log("New user");
          navigate(`/onboarding`, {
            state: {
              mockId: mockId,
              setId: setId,
            },
          });
        } else {
          //alert("Already attempted else");
          //console.log("already attempted");

          const Last_attempt_id_Obj =
            response.data.attemptList.length > 0 &&
            response?.data?.attemptList[0];
          const isVarcSubmitted = Last_attempt_id_Obj.isVarc;
          const isLrdiSubmitted = Last_attempt_id_Obj.isLrdi;
          const isQuantSubmitted = Last_attempt_id_Obj.isQuants;
          const lastAttemptedQuestionIndex =
            Last_attempt_id_Obj.lastAttemptedQuestionIndex;
          const mockTitle = Last_attempt_id_Obj.mockTitle;
          if (isVarcSubmitted && isLrdiSubmitted && isQuantSubmitted) {
            showToastMessageForAnalysis();

            setTimeout(() => {
              navigate(
                `/analysis/${mockId}/${Last_attempt_id_Obj.attemptId}/overall`
              );
            }, 2000);
          } else {
            // setting mock data from api to localstorage
            const mockData = Last_attempt_id_Obj.mockData;
            if (mockData) {
              //alert("set questions enter if");
              //const encodedString = window.btoa(encodeURIComponent(JSON.stringify(mockData)) );
              localStorage.setItem("mockTitle", mockTitle);
              localStorage.setItem(
                "lastAttemptedQuestionIndex",
                lastAttemptedQuestionIndex
              );
              //localStorage.setItem("questionStatus", encodedString);
              localStorage.setItem("questionStatus", JSON.stringify(mockData));
              localStorage.setItem("currMockId", encode(mockId));
              let encodedAttemptId = encode(Last_attempt_id_Obj.attemptId);
              localStorage.setItem("attemptId", encodedAttemptId);
              localStorage.setItem(
                "my-counter-min",
                Last_attempt_id_Obj?.timer.minutes
              );
              localStorage.setItem(
                "my-counter-sec",
                Last_attempt_id_Obj?.timer.seconds
              );
              setModal(true);
            } else {
              localStorage.clear();

              window.location.href = "https://www.iquanta.in/cat-mock-test";
            }
          }
        }
      }
    } catch (err) {
      showToastMessage(err?.response?.data?.message);
      console.log(err);
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "https://www.iquanta.in/cat-mock-test";
      }, 2000);
    }
  };

  const showToastMessageForAnalysis = () => {
    toast.info(
      "You have already attempted this mock, redirecting to your Analysis",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return null;
  };
  const showToastMessage = (msg) => {
    toast.error(msg == undefined ? "Some error occurred!" : msg, {
      position: toast.POSITION.TOP_CENTER,
    });

    return setLoader(false);
  };
  const showToastMessageForDiscard = () => {
    toast.info("Your Mock has been Discarded", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={1500} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
          <>
            {" "}
            <RingLoader color="var(--orange)" size="150px" />
            <h5
              className="loader_title"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              Authenticating..... Please wait!
            </h5>
          </>
        ) : (
          <div></div>
        )}
      </div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-center">
            <Box
              component="img"
              sx={{
                height: 200,
                width: 600,
              }}
              alt="no IMage"
              src="/ModalImage.png"
            />
          </div>
          <div className="d-flex justify-content-center p-4">
            <SubHeading
              style={{ color: "red", fontWeight: "700", fontSize: "16px" }}
            >
              You have not completed your previous mock, press continue to
              resume or press discard.
            </SubHeading>
          </div>
          <div className="d-flex justify-content-evenly">
            <MyButton
              variant="contained"
              sx={{
                bgcolor: "#EBEBEB",
                color: "black",
                borderRadius: "20px",
                ":hover": { background: "#EBEBEB", color: "black" },
                width: "138px",
              }}
              onClick={() => eraseQuestionData()}
            >
              Discard
            </MyButton>
            <MyButton
              variant="contained"
              style={buttonStyle}
              onClick={() => resumeMock()}
            >
              Continue
            </MyButton>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default MainUserAuth;
