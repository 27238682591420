import Main from "./Pages/Main";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styleSheets/Style";
import Instructions from "./Pages/Instructions";
import Terms from "./Pages/Terms";
import Protected from "./Components/Protected";
import AnalysisMain from "./Analysis/AnalysisMain";
import TopicAnalysis from "./Analysis/TopicAnalysis";
import SectionAnalysis from "./Analysis/SectionAnalysis";
import SubtopicAnalysis from "./Analysis/SubtopicAnalysis";
import DifficultyAnalysis from "./Analysis/DifficultyAnalysis";
import OverallAnalysis from "./Analysis/OverallAnalysis";
import ViewSolution from "./Pages/ViewSolution";
import UserAuth from "./Pages/UserAuth";
import LeaderBoard from "./Pages/LeaderBoard";
import AnalysisAcross from "./Pages/AnalysisAcross";
import ErrorTracker from "./Pages/ErrorTracker";
import GoalTracker from "./Pages/GoalTracker";
import MarketPlace from "./Pages/MarketPlace";
import MockComparison from "./Pages/MockComparison";
import OnBoarding from "./Pages/OnBoarding";
import MainUserAuth from "./Pages/MainUserAuth";
import MobileTemp from "./Pages/MobileTemp";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ScoreVsPrecentile from "./Pages/ScoreVsPrecentile";
import { encode, decode } from "base-64";
import CacheBuster from "react-cache-buster";
import version from "../package.json";
// import Loading from "./loading";
import { useAuth } from "./services/Context";
import OverallAcrossMob from "./Pages/MobilePages/overallAcrossMob";
import GoalTrackerMob from "./Pages/MobilePages/GoalTrackerMob";
import ErrorTrackerMob from "./Pages/MobilePages/ErrorTrackerMob";
import ViewSolutionMob from "./Pages/MobilePages/ViewSolMob";
import AnalysisMainMob from "./Analysis/AnalysisMainMob";

function App() {
  const { isMobile, setIsMobile, menuBarOpen } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);
  const isProduction =
    process.env.REACT_APP_BASE_URL === "https://devapi.iqmock.iquanta.in";

  // useEffect(() => {
  //   if (questionLStatus?.length > 0) {
  //     localStorage.setItem("quesLLtionStatus", encode(JSON.stringify(questionLStatus)));
  //   }
  // }, [questionLStatus]);

  //  create query client
  const queryClient = new QueryClient();
console.log("menu open",menuBarOpen);
  useEffect(() => {
    function handleResize() {
      const isMobileOrTablet = window.matchMedia("(max-width:1200px)").matches;
      //console.log("Is mobile or tablet:", isMobileOrTablet, previousLocation);

      if (isMobileOrTablet) {
        if (!previousLocation) {
          //console.log("tyagi");
          setPreviousLocation(location.pathname);
          // navigate("/mobileErrorPage");
          setIsMobile(true);
        }
        // if (previousLocation) {
        //   navigate("/mobileErrorPage");
        // }
      }
      if (!isMobileOrTablet && previousLocation) {
        //console.log("mantha");
        navigate(previousLocation);
        setPreviousLocation(null);
        setIsMobile(false);
      }

      console.log("Mobile view ", isMobile);
    }

    // Check the initial screen size
    handleResize();

    // Add event listener to handle screen resize
    window.addEventListener("resize", handleResize);

    // Cleanup by removing the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate, previousLocation, location]);

  // Rest of the component code...
  console.log(version);
  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/:email/:otp/:setId/:mockId"
              element={<MainUserAuth />}
            />
            <Route path="/instructions" element={<Instructions />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/user_authentication" element={<UserAuth />} />
            <Route path="/mobileErrorPage" element={<MobileTemp />} />

            <Route
              path="/analysis/:mockId/:attemptId"
              element={isMobile?<AnalysisMainMob/> : <AnalysisMain />}
            >
              <Route path="topicwise" element={<TopicAnalysis />} />
              <Route
                path="subtopicwise/:subject"
                element={<SubtopicAnalysis />}
              />
              <Route
                path="sectionwise/:subject"
                element={<SectionAnalysis />}
              />
              <Route path="overall" element={<OverallAnalysis />} />
              <Route path="difficulty" element={<DifficultyAnalysis />} />
            </Route>
            <Route
              path="/leaderboard/:mockId/:attemptId"
              element={<LeaderBoard />}
            />
            <Route
              path="/viewsolutions/:mockId/:attemptId"
              element={isMobile?<ViewSolutionMob/>:<ViewSolution />}
            />
            <Route path="/main" element={<Protected Comp={Main} />} />

            <Route
              path="/analysisacross/:mockId/:attemptId"
              element={isMobile ? <OverallAcrossMob /> : <AnalysisAcross />}
            />
            <Route
              path="/analysisacrossMob/:mockId/:attemptId"
              element={<OverallAcrossMob />}
            />
            <Route
              path="/errortracker/:mockId/:attemptId"
              element={isMobile ? <ErrorTrackerMob /> : <ErrorTracker />}
            />
            <Route
              path="/goaltracker/:mockId/:attemptId"
              element={isMobile ? <GoalTrackerMob /> : <GoalTracker />}
            />
            <Route
              path="/marketplace/:mockId/:attemptId"
              element={<MarketPlace />}
            />
            <Route
              path="/mockcomparison/:mockId/:attemptId"
              element={<MockComparison />}
            />
            <Route path="/onboarding" element={<OnBoarding />} />
            <Route
              path="/scorevspercentile/:mockId/:attemptId"
              element={<ScoreVsPrecentile />}
            />
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;
