import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Label,
} from "recharts";

function CustomDot(props) {
  const { cx, cy, stroke, strokeWidth } = props;

  return (
    <g>
      <circle cx={cx} cy={cy} r={6} fill="#E32B45" />
    </g>
  );
}

const LineGraph = ({ Data, dataKeyX, ykey, xAxisTitle, yAxisTitle }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={1000} data={Data}>
        <XAxis
          dataKey={dataKeyX}
          padding={{ left: 30, right: 30 }}
         
        >
          <Label
            value={xAxisTitle}
            position="insideBottom"
            dy={13}
            color="#2A9D8F"
            fontWeight={"bold"}
          />
        </XAxis>

        <YAxis padding={{ top: 20 }} >
          <Label
            value={yAxisTitle}
            angle={-90}
            position="insideLeft"
            dy={-10}
            color="#000000"
            fontWeight={"bold"}
          />
        </YAxis>
        <Tooltip />

        <Line
          dataKey={ykey}
          stroke="#2A9D8F"
          activeDot={{ r: 8 }}
          strokeWidth={5}
          dot={<CustomDot />}
        />
        <ReferenceLine y={0} stroke="#000" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineGraph;
