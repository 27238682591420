import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Card, Box, Avatar, CardContent } from "@mui/material";

import { TbMinusVertical } from "react-icons/tb";

import {
  StyleUl,
  HeadingPink,
  ParaGraph,
  MiniHeading,
  Styleli,
} from "../styleSheets/styledCompoent";

import { useAuth } from "../services/Context";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 10,
  boxShadow: "0px 4px 4px rgba(63, 105, 255, 0.25)",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F5F9FF",
  borderRadius: "10px",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "flex-start",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SecondAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  borderRadius: 15,
  boxShadow: "0px 4px 4px rgba(63, 105, 255, 0.25)",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));


const SecondAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme, background }) => ({
    padding: theme.spacing(2),
    // borderTop: "1px solid rgba(0, 0, 0, .125)",
    background: background,
     borderRadius:"0 0 15px 15px",
  })
);


const SecondAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "1.3rem",
          fontWeight: "bold",
          color: props.expandIconProps.color,
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F5F9FF",
  borderRadius: "10px",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "flex-start",
  },
}));



export function CustomizedAccordions(props) {
  const { truncateText } = useAuth();
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === "panel"} onChange={handleChange("panel")}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{
          backgroundColor: props.backgroundColor,
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <span>
          <img
            className="img-fluid pe-3 "
            src={props.icon}
            width={props.style ? props.style.iconSize : 50}
          />
        </span>{" "}
        <div>
          <Typography
            variant="h4"
            sx={{ fontSize: 17 }}
            pt={0.5}
            color={"black"}
          >
            {" "}
            {props.title}
          </Typography>
          <Typography
            sx={{
              color:
                props.titleDescription === "Topper Analysis"
                  ? "#black"
                  : "#809FB8",
              fontSize: 15,
            }}
          >
            {props.subtitle}
          </Typography>
          <Typography sx={{ fontSize: 15 }} color={"black"}>
            {" "}
            {props.titleDescription}
          </Typography>
        </div>
        <span> {props.select}</span>
      </AccordionSummary>
      <AccordionDetails>
        <MiniHeading className="card-title pricing-card-title ">
          <small className="ps-3">Details :</small>
        </MiniHeading>
        <StyleUl className="text-justify p-3 ms-3 ">
          {props.offer?.map((item, index) => {
            return (
              <Styleli key={index}>
                {item.name} : <b>{item.value}</b>{" "}
              </Styleli>
            );
          })}
        </StyleUl>
      </AccordionDetails>
    </Accordion>
  );
}

export const MobileViewCard = ({
  data,
  icon,
  infoIcon,
  backgroundColor,
  titleDescription,
  select,
  listData,
  style,
}) => {
 
  return (
    <div style={{ width: "100%" }}>
      <CustomizedAccordions
        // Sending Data through Props
        title={
          data.percentile === Number
            ? data.percentile + "%ile"
            : data.percentile
        }
        subtitle={data?.title}
        icon={icon}
        infoIcon={infoIcon}
        titleDescription={titleDescription}
        backgroundColor={backgroundColor}
        select={select}
        offer={listData}
        style={style}
      />
    </div>
  );
};



export function LeaderBoardCard({ Data, style, isLoading }) {
  const [expanded, setExpanded] = useState("");

  const {
    rank,
    name,
    qaPercentile,
    lrdiPercentile,
    varcPercentile,
    overallPercentile,
    overallScore,
  } = Data;

  const listContent = [
    { name: "Overall Score", value: `${overallScore}` },
    { name: "VARC", value: `${varcPercentile} Percentile` },
    { name: "LRDI", value: `${lrdiPercentile} Percentile` },
    { name: "QUANTS", value: `${qaPercentile} Percentile` },
  ];

  const getRandomColor = () => {
    const colors = ["#F87171", "#34D399", "#60A5FA", "#FBBF24", "#A78BFA"];

    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Function to get Avatar content based on name
  const getAvatarContent = (name) => {
    const nameWords = name.split(" ");
    if (nameWords.length === 2) {
      return nameWords[0][0] + nameWords[1][0]; // First letter of first word + first letter of second word
    } else {
      return name.slice(0, 1); // First two letters of name
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <SecondAccordion
      expanded={expanded === "panel"}
      onChange={handleChange("panel")}
    >
      <SecondAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{
          backgroundColor: style.background,
          alignItems: "center",
          alignContent: "center",
          color: style.color,
          borderRadius: expanded === "panel" && "15px 15px 0px 0px",
        }}
        expandIconProps={{ color: style.color }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex -start",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            alt={name}
            src="/static/images/avatar/1.jpg"
            sx={{
              width: 35,
              height: 35,
              fontSize: 14,
              flexBasis: "auto",
              m: "auto",
              background: getRandomColor(),
            }}
          >
            {getAvatarContent(name).toUpperCase()}
          </Avatar>

          <Box>
            <div
              className="d-flex flex-column align-items-start"
              style={{ borderBottom: style.border, flexBasis: "auto" }}
            >
              <Typography
                gutterBottom
                variant="h4"
                fontSize={20}
                color={style.color}
                ml={1}
              >
                {name}
              </Typography>
              <div className="d-flex">
                <div>
                  <img
                    src="/medal.png"
                    width={30}
                    className="img-fluid overflow-hidden "
                  />
                </div>

                <Typography
                  gutterBottom
                  variant="paragraph"
                  color={style.color}
                >
                  Rank :{rank}{" "}
                  <TbMinusVertical
                    style={{ color: style.color }}
                    className="fs-1"
                    gutterBottom
                  />
                  {overallPercentile} Percentile
                </Typography>
              </div>
            </div>
          </Box>
        </Box>
      </SecondAccordionSummary>
      <SecondAccordionDetails background={style.background}>
        {listContent?.map((item, index) => {
          return (
            <div className="row justify-content-start ms-2">
              <div className="col-5">
                <Typography
                  gutterBottom
                  variant="paragraph"
                  color={style.color}
                  fontWeight={"bold"}
                >
                  {item.name}
                </Typography>
              </div>
              <div className="col-6">
                <Typography
                  gutterBottom
                  variant="paragraph"
                  color={style.color}
                >
                  {item.value}
                </Typography>
              </div>
            </div>
          );
        })}
      </SecondAccordionDetails>
    </SecondAccordion>
  );
}
