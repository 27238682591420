import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { fetchAnalysisData } from "./Analysis_api";
import MuiBackdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchMockStatus } from "./Analysis_api";

export const Context = React.createContext();

export function useAuth() {
  return useContext(Context);
}

export const ContextProvider = ({ children }) => {
  const [analysisData, setAnalysisData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErr, setErr] = useState(false);
  const [menuBarOpen, setMenuBarOpen] = useState(false); //Globally state for menu bar
  const [isWindowClosed, setWindowClosed] = useState();
  const [topperData, setTopperData] = useState();
   const [mockStatus, setMockStatus] = useState({
    allIndiaRank: null,
    date: null,
  });
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  let date;

  const analysisDataApi = async (attemptId, mockId, uid) => {
    try {
      const response = await fetchAnalysisData(attemptId, uid);
      const response2 = await fetchMockStatus(mockId, attemptId, uid);

      //console.log(response2);
      if (response?.status == 200) {
        setAnalysisData(response.data.data);
        const dateObject = new Date(response.data.date);
        const formattedDate = dateObject.toLocaleDateString();
       setMockStatus((prevMockStatus) => ({
         ...prevMockStatus,
         date: formattedDate,
       }));


        console.log("main repose", response);
      } else {
        //console.log("--> Error in analysis data fetching");
        showToastMessage();
        setErr(true);
      }
      if (response2?.status == 200) {
        setWindowClosed(response2.data.isWindowClosed);
        window.localStorage.setItem("__wodniw", !response2.data.isWindowClosed);
        setTopperData(response2.data.mockData);
         setMockStatus((prevMockStatus) => ({
           ...prevMockStatus,
           allIndiaRank: response2.data.air,
         }));
        //  setMockStatus({ ...mockStatus, allIndiaRank: response2.data.air });
        // // setting mock and attempt id from params
        // localStorage.setItem("currMockId", mockId);
        // localStorage.setItem("attemptId", attemptId);
        setLoading(false);
      } else {
        //console.log("--> Error in mock status fetching");
        if (response2?.status == 500) {
          // showToastMessage(response2?.data?.message);
          window.localStorage.setItem(
            "__wodniw",
            response2.data.isWindowClosed
          );
          // setErr(true);
        }
      }
    } catch (err) {
      //console.log(err);
      if (err?.response?.status == 500) {
        showToastMessage(err?.response?.data?.message);
        // window.localStorage.setItem("__wodniw", err.response.data.isWindowClosed);
        setErr(true);
      } else {
        showToastMessage(err?.response?.data?.message);
        setErr(true);
      }
    }
  };

  const handlePageClick = () => {
    if (menuBarOpen) {
      setMenuBarOpen(false);
    }
  };

  const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }));

  const showToastMessage = (msg) => {
    toast.error(
      msg == undefined
        ? "Some error occurred! Please reload the page."
        : msg.toUpperCase(),
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const truncateText = (text, limit) => {
    const words = text.split(" ");

    if (words.length > limit) {
      const truncated = words.slice(0, limit).join(" ");
      return `${truncated} ...`;
    }

    return text;
  };

  //console.log("analysdata", mockStatus);
  //Set data to variables according to category that data exports to pages according to need
  const basicAnalysis = analysisData[0];
  const overallAnalysis = analysisData[1];
  const sectionWiseAnalysis = analysisData[2];
  const topicWiseAnalysis = analysisData[3];
  const difficulty = analysisData[4];
  const subtopicWiseAnalysis = analysisData[6];

  console.log("context analysis", analysisData);
  console.log("Mock status", mockStatus);
  console.log("topper data", topperData);

  return (
    <>
      <Context.Provider
        value={{
          basicAnalysis,
          sectionWiseAnalysis,
          subtopicWiseAnalysis,
          topicWiseAnalysis,
          overallAnalysis,
          difficulty,
          analysisDataApi,
          isLoading,
          isErr,
          setMenuBarOpen,
          menuBarOpen,
          handlePageClick,
          Backdrop,
          showToastMessage,
          setLoading,
          topperData,
          mockStatus,
          truncateText,
          isMobile,
          setIsMobile,
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
};
