import styled from "styled-components";
import { typographyStyles } from "./StyleNew";

// For main heading
export const Heading = styled.h1`
  font-weight: bold;
  color: black;
  font-size: 38px;
  font-family: var(--para-heading);
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

// for color black Paragraph with font
export const ParaGraph = styled.p`
  color: black;
  ...typographyStyles.subHeading,
    fontSize: "15px",
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;
// For grey Paragraph
export const ParaGrey = styled.p`
  color: var(--para-color);
  letter-spacing: 1px;
  font-size: 15px;
  text-align: left;
  font-family: var( --font-inter);
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

// For small heading
export const MiniHeading = styled.h5`
  font-size: 17px;
  font-weight: bold;
  font-family: var( --font-inter);
  @media (max-width: 500px) {
  }
`;
// For black heading medium size
export const SubHeading = styled.h5`
  font-size: 20px;
  font-weight: bold;
  font-family: var( --font-inter);
  @media (max-width: 500px) {
  }
`;

// For pink heading medium size
export const HeadingPink = styled.span`
  color: var(--theme-color);
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  font-family: var( --font-inter);
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

export const StyleUl = styled.ul`
  line-height: 3;
  font-family: var( --font-inter);
  padding-left: 7px;
  color: "black";
`;
export const Styleli = styled.li`
  color: black;
  font-size: 15px;
  ${"" /* list-style:none; */}
  text-decoration:none;
  font-family: var( --font-inter);
  list-style-position: outside;
`;
