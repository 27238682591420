import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterMain from "../Components/CenterMain";
import Header from "../Components/Header";

function Main() {
  

  return (
    <div>
      <Header />
      <CenterMain />
    </div>
  );
}

export default Main;
