import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAttemptId } from "../services/Mock_api";
import { RingLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { encode, decode } from "base-64";

const UserAuth = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const { state } = useLocation();
  console.log("terms and condition", state);
  useEffect(() => {
    // userAuthCheck();
    createAttemptId();
  }, []);
  let d = 67;

  // Function for checking authorising user
  // const userAuthCheck = () => {
  //   const attemptID = JSON.parse(decode(localStorage.getItem("userData")))?.attemptId;
  //   //console.log(attemptID);
  //   if (attemptID) {
  //     //console.log("userAttemptID", attemptID);
  //     //console.log("go to m0ck page");
  //     // navigate(`/main/${state.mockId}/varc`);
  //     navigate(`/instructions`, {
  //       state: {
  //       mockId : state.mockId
  //     } });
  //   } else {
  //     //console.log("you dont have an attempt id");
  //     createAttemptId();
  //   }
  // Function for creating attempt id

  const createAttemptId = async () => {
  
    try {
      const response = await getAttemptId(
        state.name,
        state.email,
        state.uid,
        state.mockId,
        state.setId
      );

      if (response?.status === 200) {
        //console.log("userauth ressss",response);
        const attemptid = response.data.attemptsId
          ? response.data.attemptsId[0].attemptId
          : response.data.attemptId;
          const mockTitle = response.data.attemptsId
          ? response.data.attemptsId[0]?.title
          : response.data.mockTitle;
        localStorage.setItem("attemptId", encode(attemptid));
        localStorage.setItem("currMockId", encode(state.mockId));
        localStorage.setItem("mockTitle",mockTitle);
        // userAuthCheck();
        navigate(`/main`, {
          state: {
            mockId: state.mockId,
            type: "varc",
          },
        });
      } 
      else if(response.status===201){
        
        showToastMessage("Selected MockID/ BundleID is not Valid!");
        localStorage.clear()
       setTimeout(()=>{
        window.location.href = "https://www.iquanta.in/cat-mock-test";
       },2000) 
        //redirect to start mock mbr
        return;
      }
      else if(response.status===202){
        showToastMessage("You Have Not Purchased This Mock!");
        localStorage.clear();
        setTimeout(()=>{
          window.location.href = "https://www.iquanta.in/cat-mock-test";
         },2000) 
      }
      else {
        showToastMessage(response?.response?.data?.message);
        
        setTimeout(()=>{
          window.location.href = "https://www.iquanta.in/cat-mock-test";
         },2000) 
      }
    } catch (err) {
      //console.log(err);
      //alert("!");
      localStorage.clear();
      showToastMessage("Please Try Again!");
      setTimeout(()=>{
        window.location.href = "https://www.iquanta.in/cat-mock-test";
       },2000) 
      
    }
  };
  const showToastMessage = (msg) => {
    toast.error(msg == undefined ? "Some error occurred!" : msg.toUpperCase(), {
      position: toast.POSITION.TOP_CENTER,
    });
    setTimeout(() => {
      localStorage.clear();
      // window.location.href = "https://www.iquanta.in/icat-mocks";
    }, 4000);
    return setLoader(false);
  };

  // //console.log("On boardinf=g res", response)
  return (
    <React.Fragment>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
          <>
            {" "}
            <RingLoader color="var(--orange)" size="150px" />
            <h5
              className="loader_title"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              Loading..... Please wait!
            </h5>
          </>
        ) : (
          <div></div>
        )}
      </div>
    </React.Fragment>
  );
};
export default UserAuth;
