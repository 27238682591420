import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { typographyStyles } from "../styleSheets/StyleNew";
import { Stack, Paper } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  height: "auto",
  display: "flex",
  justifyContent: "space-around",
  columnGap: 2,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  border: "0px",
  p: 2,
};

export default function TransitionsModal({isOpen}) {
  const [open, setOpen] = React.useState(isOpen);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            // timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <img src="/wpImage.png" alt="This sections are not open" className="img-fluid" width={350} height={400}></img>
            <div className="container p-5">
              <Typography id="transition-modal-description" sx={{ ...typographyStyles.mainHeading, color: "#000AFF" }}>
                Window Period Is Open.
              </Typography>
              <Typography id="transition-modal-description" sx={{ ...typographyStyles.subHeading, color: "#000000", my: 2 }}>
                Complete Analysis will be available from:
              </Typography>
              <Typography id="transition-modal-description" sx={{ ...typographyStyles.subHeading, color: "#000000" }}>
                18th July 2023
              </Typography>
              <hr />
              <Typography
                id="transition-modal-description"
                sx={{
                  ...typographyStyles.subHeading,
                  color: "#000000",
                  fontSize: 21,
                }}
              >
                Sections Locked:
              </Typography>
              <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-around"} gap={2} sx={{ mt: 2 }}>
                {["view-sol-menu.png", "podium1.png", "errorTracker.png", "mockCompare.png"].map((item) => (
                  <Box
                    component={Paper}
                    sx={{
                      width: "59px",
                      height: "59px",
                      boxShadow: 2,
                      justifyContent: "center",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center", // Vertically center the content
                    }}
                  >
                    <img src={item} alt="" className="img-fluid" width={27} />
                  </Box>
                ))}
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
