import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {IconButton} from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useAuth } from "../services/Context";
import { Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { typographyStyles } from "../styleSheets/StyleNew";
import Typography from "@mui/material/Typography";
import { BiMenu } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { DrawerData } from "../services/DataFiles";

export default function SwipeableTemporaryDrawer() {
  const navigate = useNavigate();
  const params = useParams();
  const drawer = DrawerData(params.mockId, params.attemptId);
  const { menuBarOpen ,setMenuBarOpen} = useAuth();
  const location = useLocation();
  const [state, setState] = React.useState({
    left: false,
  });


  useEffect(() => {
    setMenuBarOpen(state.left)
  }, [state])
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  console.log(location)
  
  return (
    <div>
      <BiMenu
        onClick={toggleDrawer("left", true)}
        className={
          location.pathname === `/goaltracker/${params.mockId}/${params.attemptId}`
            ? "display-6 fw-bold text-light me-1"
            : "display-6 fw-bold text-dark me-1"
        }
      />

      <SwipeableDrawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box
          sx={{ width: "auto" }}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <List>
            <img
              src={"/iQuanta.png"}
              alt="iquanta_logo"
              width={100}
              className="img-fluid m-4 "
            />
            <Divider />
            {drawer &&
              drawer.map((item, ind) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{
                    display: "block",
                    background:
                      location.pathname === item.path ? "#d4d5d6" : "inherit",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: menuBarOpen ? "initial" : "center",

                      px: 1.5,
                      mb: 1,
                    }}
                    onClick={() => navigate(item.path)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: menuBarOpen ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/" + item.icon}
                        className="img-fluid"
                        width={22}
                        alt=""
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="paragraph"
                        sx={{
                          ...typographyStyles.subHeading,
                          fontWeight: 500,
                          fontSize: 15,

                          pl: 1,
                        }}
                      >
                        {item.text}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
          <Divider />
          <List
            sx={{
              position: "absolute",
              bottom: 0,
            }}
          >
            {[
              {
                name: "View All Mocks",
                icon: "allMock.png",
              },
            ].map((item, index) => (
              <ListItem
                key={item}
                disablePadding
                sx={{ display: "block", width: "auto" }}
              >
                <Tooltip title={item.name} key={index} placement="right" arrow>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: menuBarOpen ? "initial" : "center",
                      px: 1.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: menuBarOpen ? 3 : "auto",
                        justifyContent: "center",
                        pr: 1,
                      }}
                    >
                      <a
                        href="https://www.iquanta.in/cat-mock-test"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img
                          src={"/" + item.icon}
                          alt="no image"
                          height={30}
                          width={30}
                        />
                      </a>
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
