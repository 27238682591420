import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { SubHeading } from "./../styleSheets/Style";
import { useState } from "react";
import { useEffect } from "react";
import Latex from "react-latex-next";


const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  textAlign: "",
  height: "80vh",
  bgcolor: "white",
  borderRadius: "10px ",
  boxShadow: 24,
  p: 2,
  backroundSize: "cover",
  backgroundRepeat: "no-repeat",
  objectfit: "cover",

  // Add this line to set a higher z-index value
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    border: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  //   backgroundColor:
  //     theme.palette.mode === "dark"
  //       ? "rgba(255, 255, 255, .05)"
  //       : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    fontSize: 16,
    fontFamily: "var(--font-inter)",
    fontWeight: 600,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontFamily: "var(--font-inter)",
  fontWeight: "bold",
}));

const Title = styled(Typography)({
  fontSize: "16px",
  fontFamily: "var(--font-inter)",
  fontWeight: 600,
  color: "#333",
});

export default function CustomizedAccordions({ data, articleList }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState();
  //console.log(articleList)

  const handleOpen = (type, topic, accuracy) => {
    setOpen(true);
    //console.log(type, topic, accuracy);
    const arr = articleList.filter(
      (e) => e.section === type && e.topic === topic
    );
    //console.log(arr[0].articles);
    for (const acc of arr[0].articles) {
      if (accuracy <= acc.accuracy) {
        //console.log(acc);
        setContent(acc.article)
        break;
      }
    }
    // //console.log(filteredArticle)
  };
  const handleClose = () => {
    setContent(null);
    setOpen(false)
  }
  const [expandVarc, setExpandVarc] = React.useState(false);
  const [expandLrdi, setExpandLrdi] = React.useState(false);
  const [expandQuant, setExpandQuant] = React.useState(false);

  return (
    <div>
      <Accordion
        expanded={expandVarc}
        onChange={() => setExpandVarc(!expandVarc)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Title>VARC</Title>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              {"Topics"}
            </Typography>
            {/* <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>{"Accuracy"}</Typography> */}
          </Box>
          <Typography>
            {data &&
              data.varc.map((e, ind) => {
                return (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    <Typography sx={{ color: "#7C7C7C", fontSize: "1em" }}>
                      {e.topic}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        color: "#0038FF",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                      onClick={() => handleOpen("varc", e.topic, e.accuracy)}
                    >
                      {"How to improve?"}
                    </Typography>
                  </Box>
                );
              })}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandLrdi}
        onChange={() => setExpandLrdi(!expandLrdi)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Title>LRDI</Title>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              {"Topics"}
            </Typography>
            {/* <Typography sx={{textDecoration : "underline", color : "#0038FF"}}>{"How to improve?"}</Typography> */}
          </Box>
          <Typography>
            {data &&
              data.lrdi.map((e, ind) => {
                return (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    <Typography sx={{ color: "#7C7C7C", fontSize: "1em" }}>
                      {e.topic}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        color: "#0038FF",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                      onClick={() => handleOpen("lrdi", e.topic, e.accuracy)}

                    >
                      {"How to improve?"}
                    </Typography>
                  </Box>
                );
              })}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandQuant}
        onChange={() => setExpandQuant(!expandQuant)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Title>QA</Title>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              {"Topics"}
            </Typography>
            {/* <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>{"Accuracy"}</Typography> */}
          </Box>
          <Typography>
            {data &&
              data.quants.map((e, ind) => {
                return (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    <Typography sx={{ color: "#7C7C7C", fontSize: "1em" }}>
                      {e.topic}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        color: "#0038FF",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                      onClick={() => handleOpen("quants", e.topic, e.accuracy)}

                    >
                      {"How to improve?"}
                    </Typography>
                  </Box>
                );
              })}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ zIndex: 1000 }}>
        {" "}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{...style, overflowY : "scroll"}}>
            <div className="d-flex justify-content-center">
              <SubHeading className="m-0 ps-3">{""}</SubHeading>
            </div>
            <Typography><Latex>{content ? content : ""}</Latex></Typography>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
