import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Typography from "antd/es/typography/Typography";
import { Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Avatar from "@mui/material/Avatar";
import { encode, decode } from "base-64";
import SwipeableTemporaryDrawer from "./MobileDrawer";
import { useAuth } from "../services/Context";


function HeaderNew({ style, logoPath }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { _id, name, photoURL, mbrId } = userData;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {isMobile }=useAuth()
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className="w-100 p-1">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="flex-item ">
          <Box sx={{ display: "flex" }}>
            {isMobile && (
              <div className="pr-2 mb-1">
                <SwipeableTemporaryDrawer />
              </div>
            )}
            <img
              src={logoPath || "/iQuanta.png"}
              alt="iquanta_logo"
              width={140}
              className="img-fluid"
            />
          </Box>
        </div>

        <div className="d-flex gap-2 ">
          <div className="text-end">
            <Typography
              style={{
                fontFamily: "var(--font-inter)",
                fontSize: "19px",
                fontWeight: 700,
                ...style,
              }}
            >
              {name}
            </Typography>
            <Typography
              style={{
                fontFamily: "var(--font-inter)",
                fontSize: "12.5px",
                color: "gray",
                fontWeight: 550,
                ...style,
              }}
            >
              UID : {mbrId}
            </Typography>
          </div>

          <div className="d-flex">
            {/* <img
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
              src={photoURL}
              alt="mdo"
              width="50"
              height="50"
              className="rounded"
            /> */}
            <Avatar
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              alt="Remy Sharp"
              src={photoURL}
              sx={{
                width: 50,
                height: 50,
                cursor: "pointer",
                ":hover": {
                  bgcolor: "#AF5",
                  color: "white",
                },
              }}
            />
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.iquanta.in/cat-mock-test"
                  target="_blank"
                >
                  View All Mocks
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.iquanta.in/details/cat-2023-full-course"
                  target="_blank"
                >
                  CAT Course Details{" "}
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://www.iquanta.in/blog/"
                  target="_blank"
                >
                  Blog{" "}
                </a>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderNew;
