import Box from "@mui/material/Box";
import "../styleSheets/Calculator.css";
import Modal from "@mui/material/Modal";
import React, { Component } from "react";
import { RxCross1 } from "react-icons/rx";
import { SubHeading } from "./../styleSheets/Style";
import { MyButton } from "./../styleSheets/Style";
import { Typography } from "@mui/material";
import { useState } from "react";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  textAlign: "",
  height: 650,
  bgcolor: "white",
  borderRadius: "10px ",
  boxShadow: 24,
  p: 2,
  backgroundImage: "url('/instructions.jpg')",
  backroundSize: "cover",
  backgroundRepeat: "no-repeat",
  objectfit: "cover",
  overflowY : "scroll"

  // Add this line to set a higher z-index value
};

export default function InstructionButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

  function onResultChange(result) {
    //console.log(result);
  }

  return (
    <span>
      <MyButton
        variant="contained"
        height="41"
        sx={{
          width: "150px",
          margin: 0,
          marginTop: "1em",
          ":hover": { boxShadow: 5 },
        }}
        onClick={handleOpen}
      >
        Instructions
      </MyButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-center">
            <SubHeading className="m-0 ps-3">Instructions </SubHeading>
          </div>
          <Typography>
            <ul style={{ listStyleType: "disc", marginTop: "20px" }}>
              <li style={{ fontWeight: "700" }}>
                {" "}
                The clock has been set on the server and countdown timer at top right corner of your screen will display the remaining time for you to complete the exam. When the clock runs out the exams ends by default- you are not required to end or submit your exam
              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              The Marked for Review status simply acts as a reminder that you have set to look at the question again. If an answer is selected for a question that is Marked for Review, the answer will be considered in the final evaluation

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              Navigation to a question

To select a question to answer, you can do one of the following:
Click on the question number on the question palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question

Click on Save and Next to save answer to current question, mark it for review, and to go to the next question in sequence

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              You can view the entire paper by clicking on the All Questions button
Answering questions

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              For multiple choice type question
To select your answer, click on one of the option buttons

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              To change your answer, click the another desired option button

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              To save your answer, you MUST click on save

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              To deselect a chosen answer, click on the chosen option again or click on the Clear Response button


              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              To mark a question for review click on Mark for Review & Next If an answer is selected for a question that is Marked for Review, the answer will be considered in the final evaluation
              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              To change an answer to a question, first select the question and then click on the new answer option followed by a click on the save button
Questions that are saved or marked for review after answering will ONLY be considered for evaluation
If quiz is paused, quiz can be continued from where left off within scheduled time

              </li>
              <li style={{ fontWeight: "700", marginTop: "20px" }}>
              Navigation through sections :

Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by clicking on the section name. The section you are currently viewing is highlighted
After clicking the save button on the last question for a section,you will automatically be taken to the first 

              </li>

            </ul>
          </Typography>
        </Box>
      </Modal>
    </span>
  );
}
