import { Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { BootstrapButton, SubHeading } from "../styleSheets/Style";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function Terms() {
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  // //console.log(params)
  return (
    <div className="container overflow-hidden d-flex-col justify-content-center align-content-center">
      <div className="logo container text-center my-4">
        <img
          src={require("../images/iQuanta.png")}
          className="img-fuid mx-auto"
          width={150}
          alt=""
        />
      </div>

      <div className="container mt-3 p-2 text-center">
        <SubHeading className="text-center"> Terms & conditions</SubHeading>
        <div
          className="container m-2 p-4 mx-auto overflow-auto text-start"
          style={{
            border: "2px solid black",
            background: "var(--background-color)",
            height: "60vh",
          }}
        >
          <div className=" d-flex justify-content-center ">
            <div className=" flex-item text-start overflow-auto p-3 ">
              <Typography variant="text">
                <b>Declaration by the candidate:</b>
              </Typography>
              <div className="ps-3">
                <ul
                  style={{ listStylePosition: "outside", paddingLeft: "0px" }}
                >
                  <li>
                    <Typography variant="text">
                      I have read and understood all the above instructions. I
                      have also read and understood clearly the instructions
                      given on the admit card and shall follow the same. I also
                      understand that in case I am found to violate any of these
                      instructions, my candidature is liable to be cancelled. I
                      also confirm that at the start of the examination all the
                      computer hardware allotted to me are in proper working
                      condition.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="text">
                      I will not disclose, publish, reproduce, transmit, store,
                      or facilitate transmission and storage of the contents of
                      the CAT or any information therein in whole or part
                      thereof in any form or by any means, verbal or written,
                      electronically or mechanically for any purpose. I am aware
                      that this shall be in violation of the Indian Contract
                      Act, 1872 and/or the Copyright Act, 1957 and/or the
                      Information Technology Act, 2000. I am aware that such
                      actions and/or abetment thereof as aforementioned may
                      constitute a cognizable offence punishable with
                      imprisonment for a term up to three years and fine up to
                      Rs. Two Lakhs. I agree to this Non-Disclosure Agreement
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center  align-self-center gap-5 my-3">
          <BootstrapButton
            variant="contained mx-auto"
            disabled={agree ? true : false}
            height="45"
            sx={{
              color: agree ? "black" : "white",
              p: 2.5,
              backgroundColor: agree ? "#d2d4d6" : "",
              borderRadius: "30px",
            }}
            onClick={() => setAgree(true)}
          >
            Agree
          </BootstrapButton>

          <BootstrapButton
            variant="contained mx-auto"
            disabled={agree ? false : true}
            height="45"
            sx={{
              color: "white",
              p: 2.5,
              background: agree ? "" : "#d2d4d6",
              borderRadius: "30px",
            }}
            onClick={() =>
              navigate(`/user_authentication`, {
                state: {
                  name: state.name,
                  email: state.email,
                  uid: state.uid,
                  setId: state.setId,
                  mockId: state.mockId,
                },
              })
            }
          >
            Start Test
          </BootstrapButton>
        </div>
      </div>
    </div>
  );
}

export default Terms;
