import React from 'react'
import { useParams,useLocation } from 'react-router-dom';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
  } from "recharts";
  import { Typography } from "@mui/material";

function BarGrapgh({Data,title, width ,legend }) {
  const location = useLocation();
  const params = useParams();
  //console.log(Data, title)

  return (
    <div
      className="flex-item flex-fill shadow "
      style={{
        background:"white",
        width: width,
        height: "auto",
        borderRadius: "15px",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "var(--inter)",
          fontWeight: 500,
          fontSize: "30px",
          color: "#434141",
          ml: 7,
          py: 3,
        }}
      >
        {title}
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={[Data]}
          margin={{
            top: 5,
            right: 30,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {legend && (
            <Legend layout="horizontal" verticalAlign="bottom" align="center" />
          )}

          <Bar
            dataKey="total"
            fill="url(#total)"
            radius={[10, 10, 0, 0]}
            barSize={50}
          />
          <Bar
            barSize={50}
            dataKey="incorrect"
            fill="url(#incorrect)"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            barSize={50}
            dataKey="correct"
            fill="url(#correct)"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            barSize={50}
            dataKey="skipped"
            fill="url(#skipped)"
            radius={[10, 10, 0, 0]}
          />

          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#9E00FF" />
              <stop offset="100%" stopColor="#9E00FF" />
            </linearGradient>
            <linearGradient id="correct" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#71FB5B" /> ,
              <stop offset="100%" stopColor="#A3FFA1" />
            </linearGradient>
            <linearGradient id="incorrect" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FF3939" />
              <stop offset="100%" stopColor="#FF2574" />
            </linearGradient>
            <linearGradient id="skipped" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3B29FF" />
              <stop offset="100%" stopColor="#7064FC" />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarGrapgh